export type DateFormatOption =
  | 'withDayOfWeek'
  | 'withTime'
  | 'withTimeAndWeekDay'
  | 'onlyTime';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  locale: string;
  hour12: boolean | undefined;
  constructor() {
    this.locale = navigator.language;
    this.hour12 = new Intl.DateTimeFormat(this.locale, {
      hour: 'numeric',
    }).resolvedOptions().hour12;
  }

  getLocale() {
    return this.locale;
  }

  formatDate(
    dateOrDateString: string | Date | undefined | null,
    dateFormatOption?: DateFormatOption,
  ): string {
    const date: Date | null | undefined =
      typeof dateOrDateString === 'string'
        ? dateOrDateString
          ? new Date(dateOrDateString)
          : null
        : dateOrDateString;

    if (!date) {
      return '';
    }

    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000,
    );

    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: this.hour12,
    };

    const zeroPadOptions: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    };

    const weekDayOptions: Intl.DateTimeFormatOptions = {
      weekday: 'long',
    };

    if (dateFormatOption === 'onlyTime') {
      return localDate.toLocaleTimeString(this.locale, timeOptions);
    } else if (dateFormatOption === 'withDayOfWeek') {
      return new Intl.DateTimeFormat(this.locale, {
        ...zeroPadOptions,
        ...weekDayOptions,
      }).format(localDate);
    } else if (dateFormatOption === 'withTime') {
      console.log(this.hour12);
      return new Intl.DateTimeFormat(this.locale, {
        ...zeroPadOptions,
        ...timeOptions,
      }).format(localDate);
    } else if (dateFormatOption === 'withTimeAndWeekDay') {
      return new Intl.DateTimeFormat(this.locale, {
        ...zeroPadOptions,
        ...timeOptions,
        ...weekDayOptions,
      }).format(localDate);
    } else {
      return new Intl.DateTimeFormat(this.locale, {
        ...zeroPadOptions,
      }).format(localDate);
    }
  }
}
